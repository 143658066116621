import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import aboutStyles from "../css/about.module.css"

const About = () => (
  <Layout>
    <SEO title="About" />
    <div className={aboutStyles.container}>
      <div className={aboutStyles.descriptionSection}>
        <div>
          <img
            className={aboutStyles.aboutMePic}
            src="https://res.cloudinary.com/jackiesue/image/upload/c_scale,w_500/v1631370771/profile_pics/IMG_0975_lznsdx.jpg"
            alt="profile picture of me looking into makeup compact"
          />
        </div>
        <div className={aboutStyles.aboutMe}>
          <p>
            Hello, beauty! I'm Jackie Sue and I am a professionally trained
            makeup artist based in the gorgeous state of New Mexico. I offer a
            wide range of makeup services, including bridal/special events, HD
            photography/editorial, SFX, and much more.
          </p>
          <p>
            I deeply appreciate the role I get to play in my client's special
            moments. From my brides and grooms, who sit in my makeup chair and
            share their love stories, to my photography clients, about to
            capture a memory forever.
          </p>
          <p>
            I love creating a calm space for clients to feel pampered and
            relaxed.{" "}
          </p>
        </div>
      </div>
      <div className={aboutStyles.descriptionSection}>
        <div
          style={{ marginLeft: "0", marginRight: "30px" }}
          className={aboutStyles.aboutMe}
        >
          <p>To me, makeup is very much a ritual of self love. </p>
          <p>
            My love affair with makeup began as a love affair with myself. It
            was the way that I redesigned a harsh perception of myself into a
            genuine confidence. So often when a client sits in my chair, the
            first thing I’m told is “I’m sorry about the texture of my skin,
            about the shape of my nose, about the size of my lips…” The list
            goes on! And I always respond in the same way: “Do NOT apologize for
            how you look, to me or anyone else.”
          </p>
          <p>
            I believe that makeup is not about correcting anything, but
            enhancing the features that make you feel like your best self. The
            air of reassurance and confidence that my clients leave with is
            truly what makes them stunning.{" "}
          </p>
        </div>
        <div>
          <img
            className={aboutStyles.aboutMePic}
            src="https://res.cloudinary.com/jackiesue/image/upload/c_scale,w_500/v1631370762/profile_pics/5C1112EC-C500-4A76-803B-BB8790F32C16_gennhd.jpg"
            alt="profile pic"
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default About
